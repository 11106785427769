import { useDisclosure } from "@mantine/hooks";
import { useState } from "react";
import { BsEnvelope } from "react-icons/bs";
import ReactImageGallery from "react-image-gallery";
import "../App.css";
import ImageWithFadeIn, { FadeIn } from "../components/FadeInImage";
import Header from "../components/Header";
import Layout from "../components/Layout";
import Modal, { ModalClose } from "../components/Modal";
import Popup, { PopupClose } from "../components/Popup";
import { wsLink } from "../utils/format";
import { images } from "./studio";

function Label() {
  const menuDisclosure = useDisclosure(false);
  const modalDisclosure = useDisclosure(false);
  const popUpDisclosure = useDisclosure(false);

  const [popUpContent, setPopUpContent] = useState("");

  return (
    <Layout menuDisclosure={menuDisclosure}>
      <main className="flex flex-col min-h-screen w-screen items-center justify-center bg-black">
        <Header menuDisclosure={menuDisclosure} />
        <div className="flex flex-col lg:flex-row h-full lg:h-fit w-full justify-center lg:pt-[84px] gap-12 md:gap-12 lg:gap-20 lgxl:gap-24 xl:gap-16 2xl:gap-20 sm:py-12 lg:py-0 px-10">
          <div className="flex flex-col gap-12 max-w-screen-xl py-6 md:py-0 md:pb-6">
            <div className="flex flex-col md:flex-row gap-8 flex-wrap justify-center items-center">
              <ImageWithFadeIn src="/assets/label/plastic.svg" alt="plastic" />
              <ImageWithFadeIn
                src="/assets/label/universal-music-malaysia.svg"
                alt="universal-music-malaysia"
              />
              <ImageWithFadeIn src="/assets/label/pu4lyf.svg" alt="pu4lyf" />
            </div>
            <div className="flex flex-col text-text font-serif text-center justify-center items-center gap-5">
              <div>
                PU4LYF Entertainment is an esteemed independent record label
                that has made a significant impact in the music landscape since
                its inception. With a diverse catalog of over 15 albums and more
                than 350 singles, PU4LYF has collaborated with hundreds of both
                Malaysian and international recording artists.
              </div>

              <div>
                Renowned for its commitment to quality, PU4LYF Entertainment has
                expertly produced, mixed and mastered numerous chart-topping
                songs, establishing a reputation for excellence in sound
                engineering. The label's dedication to artistry and innovation
                has been recognized with multiple awards, including accolades
                for songwriting, Best Artists, Best Rapper, Song of the Year,
                Best Collaboration, Best Sound Engineering and more, as well as
                honors for Most Influential Record Label award at the VIE
                International Awards 2018 in Chennai, India.
              </div>

              <div>
                Having managed over 45 independent artists, PU4LYF Entertainment
                has played a pivotal role in nurturing talent, helping them
                evolve into some of the industry's most celebrated performers.
                The label's extensive experience extends beyond music
                production. It has also provided soundtracks, recording
                services, mixing and mastering, voiceovers and jingles for major
                broadcasting networks, film companies, artists both local and
                internationally.
              </div>

              <div>
                A decade-long partnership with Universal Music Malaysia has
                further solidified PU4LYF Entertainment's position in the
                industry, enhancing its capabilities in publishing and
                performance rights. With a deep commitment to fostering
                creativity and pushing the boundaries of music, PU4LYF
                Entertainment continues to be a driving force in the evolution
                of the entertainment industry.
              </div>
            </div>

            <div className="hidden lg:flex flex-col flex-wrap text-primary justify-center items-center pt-4">
              <div className="flex flex-wrap gap-3">
                {/* <ImageWithFadeIn
                  src="/assets/label/composition.svg"
                  alt="composition"
                />
                <div>|</div>
                <ImageWithFadeIn
                  src="/assets/label/recording.svg"
                  alt="recording"
                />
                <div>|</div> */}
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("publishing");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/publishing-and-distribution.svg"
                    alt="publishing"
                  />
                </PopUpText>
                <div>|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("project");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/project.svg"
                    alt="proejct"
                  />
                </PopUpText>
                {/* <ImageWithFadeIn
                  src="/assets/label/distribution.svg"
                  alt="distribution"
                /> */}
                <div>|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("pr");
                  }}
                >
                  <ImageWithFadeIn src="/assets/label/pr.svg" alt="pr" />
                </PopUpText>
                {/* <ImageWithFadeIn src="/assets/label/sound.svg" alt="sound" /> */}
              </div>
              {/* <div className="flex flex-wrap gap-3">
                <ImageWithFadeIn src="/assets/label/mixing.svg" alt="mixing" />
                <div>|</div>
                <ImageWithFadeIn
                  src="/assets/label/project.svg"
                  alt="proejct"
                />
                <div>|</div>
                <ImageWithFadeIn src="/assets/label/pr.svg" alt="pr" />
              </div> */}
            </div>

            <div className="flex lg:hidden flex-col flex-wrap text-primary pt-4">
              <div className="flex flex-col md:flex-row flex-wrap gap-3 justify-center items-center">
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("publishing");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/publishing-and-distribution.svg"
                    alt="publishing"
                  />
                </PopUpText>
                <div className="hidden md:block">|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("project");
                  }}
                >
                  <ImageWithFadeIn
                    src="/assets/label/project.svg"
                    alt="project"
                  />
                </PopUpText>
                <div className="hidden md:block">|</div>
                <PopUpText
                  onClick={() => {
                    popUpDisclosure[1].open();
                    setPopUpContent("pr");
                  }}
                >
                  <ImageWithFadeIn src="/assets/label/pr.svg" alt="pr" />
                </PopUpText>
              </div>
            </div>
            {/* <div className="flex lg:hidden flex-col flex-wrap text-primary pt-4">
              <div className="flex flex-col md:flex-row flex-wrap gap-3 justify-center">
                <ImageWithFadeIn
                  src="/assets/label/composition.svg"
                  alt="composition"
                />
                <div className="hidden md:block">|</div>
                <ImageWithFadeIn
                  src="/assets/label/recording.svg"
                  alt="recording"
                />
                <div className="hidden md:block">|</div>
                <ImageWithFadeIn
                  src="/assets/label/publishing.svg"
                  alt="publishing"
                />
                <div className="hidden md:block">|</div>
                <ImageWithFadeIn
                  src="/assets/label/distribution.svg"
                  alt="distribution"
                />
                <div className="hidden md:block">|</div>
                <ImageWithFadeIn src="/assets/label/sound.svg" alt="sound" />
                <div className="hidden md:block">|</div>
                <ImageWithFadeIn src="/assets/label/mixing.svg" alt="mixing" />
                <div className="hidden md:block">|</div>
                <ImageWithFadeIn
                  src="/assets/label/project.svg"
                  alt="proejct"
                />
                <div className="hidden md:block">|</div>
                <ImageWithFadeIn src="/assets/label/pr.svg" alt="pr" />
              </div>
            </div> */}
            <div className="flex flex-col gap-8 justify-center items-center">
              <div
                className="border border-border select-none w-36 text-neutral-200 h-10 gap-2 px-3 min-w-20 items-center justify-center cursor-pointer flex font-light hover:text-primary hover:border-primary duration-150 transition"
                onClick={modalDisclosure[1].open}
              >
                GALLERY
              </div>
              <div className="flex gap-5 items-center justify-center">
                <div className="w-fit text-primary hover:scale-110 transform duration-150">
                  <a
                    href="mailto:pu4lyfasia@gmail.com"
                    onClick={(event) => {
                      event.preventDefault(); // Prevent the default action

                      // Email recipient
                      var recipient = "pu4lyfasia@gmail.com";

                      // For Android: Gmail app deep link using 'intent://'
                      var androidGmailUrl =
                        "intent://send?to=" +
                        recipient +
                        "#Intent;package=com.google.android.gm;end";

                      // For iOS: Gmail app URL scheme
                      var iosGmailUrl = "googlegmail://co?to=" + recipient;

                      // Fallback to Gmail web for other platforms
                      var fallbackUrl =
                        "https://mail.google.com/mail/?view=cm&fs=1&to=" +
                        recipient;

                      // Detect if the user is on a mobile device
                      var isAndroid = /android/i.test(navigator.userAgent);
                      var isIOS = /iphone|ipod|ipad/i.test(navigator.userAgent);

                      if (isAndroid) {
                        // Try to open the Gmail app on Android
                        window.location.href = androidGmailUrl;
                      } else if (isIOS) {
                        // Try to open the Gmail app on iOS
                        window.location.href = iosGmailUrl;
                      } else {
                        // For desktop or unsupported platforms, open Gmail in the browser
                        window.location.href = fallbackUrl;
                      }
                    }}
                  >
                    <FadeIn className="h-[48px] w-[48px] cursor-pointer">
                      <BsEnvelope className="h-[48px] w-[48px] cursor-pointer" />
                    </FadeIn>
                  </a>
                </div>
                <div className="w-fit hover:scale-110 transform duration-150">
                  <a
                    target="_blank"
                    href="https://www.instagram.com/pu4lyf.ent/"
                  >
                    <ImageWithFadeIn
                      src="/assets/landing/Instagram.svg"
                      alt="Instagram"
                      className="h-10 cursor-pointer"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Popup
          handlers={popUpDisclosure[1]}
          opened={popUpDisclosure[0]}
          size="max-w-screen-lg"
          closeOnOverlay
        >
          <div className="flex text-white justify-center items-center relative min-h-[500px] h-full w-full">
            <div className="absolute right-3 top-3">
              <PopupClose handlers={popUpDisclosure[1]} />
            </div>
            {popUpContent === "publishing" && (
              <div className="w-full flex flex-col gap-16 justify-center items-center p-6">
                <ImageWithFadeIn
                  src="/assets/label/publishing-and-distribution.svg"
                  alt="Publishing"
                  className="h-6 cursor-pointer"
                />
                <div className="max-w-[650px] font-normal text-left font-serif">
                  PU4LYF Entertainment is a major affiliated publishing company
                  partnered with Universal Music Publishing for licensing
                  administration. With extensive experience, we excel in:
                  <ul className="text-left pt-5">
                    <li>* Making deals with songwriters.</li>
                    <li>
                      * Promoting songs for advertising, movies, or promotional
                      campaigns.
                    </li>
                    <li>* Issuing licenses for the songs we manage.</li>
                    <li>
                      * Collecting licensing fees on behalf of songwriters.
                    </li>
                    <li>* Payment and distribution of royalties.</li>
                    <li>
                      * Providing feedback and suggestions for improvement.
                    </li>
                    <li>* Facilitating collaborations between songwriters.</li>
                  </ul>
                </div>
                <a
                  target="_blank"
                  href={wsLink("Publishing%20and%20Distribution")}
                >
                  <div className="border border-border select-none w-36 text-neutral-200 h-10 gap-2 px-3 min-w-20 items-center justify-center cursor-pointer flex font-light hover:text-primary hover:border-primary duration-150 transition">
                    SIGN UP
                  </div>
                </a>
              </div>
            )}
            {popUpContent === "project" && (
              <div className="w-full flex flex-col gap-16 justify-center items-center p-6">
                <ImageWithFadeIn
                  src="/assets/label/project.svg"
                  alt="Project"
                  className="h-6 cursor-pointer"
                />
                <div className="max-w-[600px] font-normal text-center font-serif">
                  With extensive experience in executive producing over 25
                  albums, PU4LYF Entertainment offers comprehensive support to
                  our artists. We ensure they are well-trained in recording and
                  vocal techniques, developing unique identities and personas,
                  album design, fashion styling, and delivering exceptional
                  performances on global stages.
                </div>
                <a target="_blank" href={wsLink("Project%20Curation")}>
                  <div className="border border-border select-none w-36 text-neutral-200 h-10 gap-2 px-3 min-w-20 items-center justify-center cursor-pointer flex font-light hover:text-primary hover:border-primary duration-150 transition">
                    SIGN UP
                  </div>
                </a>
              </div>
            )}
            {popUpContent === "pr" && (
              <div className="w-full flex flex-col gap-8 justify-center items-center p-6">
                <ImageWithFadeIn
                  src="/assets/label/pr.svg"
                  alt="PR"
                  className="h-6 cursor-pointer"
                />
                <div className="max-w-[650px] font-normal text-left font-serif">
                  At PU4LYF Entertainment, we craft impactful public relations
                  and digital marketing strategies to amplify brand visibility
                  and connect with diverse audiences. Our expertise includes:
                  <ul className="text-left py-5">
                    <li>
                      * Developing tailored campaigns to promote artists,
                      projects, and events.
                    </li>
                    <li>
                      * Managing online presence across social media, websites,
                      and digital platforms.
                    </li>
                    <li>
                      * Creating compelling content, including teasers,
                      trailers, and promotional assets.
                    </li>
                    <li>
                      * Engaging audiences through interactive campaigns,
                      influencer collaborations, and targeted ads.
                    </li>
                  </ul>
                  With a deep understanding of the entertainment industry and a
                  proven track record, we ensure your message reaches the right
                  audience and leaves a lasting impression.
                </div>
                <a
                  target="_blank"
                  href={wsLink("PR%20and%20Digital%20Marketing")}
                >
                  <div className="border border-border select-none w-36 text-neutral-200 h-10 gap-2 px-3 min-w-20 items-center justify-center cursor-pointer flex font-light hover:text-primary hover:border-primary duration-150 transition">
                    SIGN UP
                  </div>
                </a>
              </div>
            )}
          </div>
        </Popup>
        <Gallery
          handlers={modalDisclosure[1]}
          opened={modalDisclosure[0]}
          images={images}
        />
      </main>
    </Layout>
  );
}

export default Label;

export const PopUpText = ({ children, onClick }: any) => {
  return (
    <div
      className="w-fit flex items-center hover:scale-105 transform duration-150 cursor-pointer"
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export const Gallery = ({ handlers, opened, images }: any) => {
  return (
    <Modal handlers={handlers} opened={opened} closeOnOverlay>
      <div className="absolute top-3 right-3 z-10">
        <ModalClose handlers={handlers} />
      </div>
      {/* <div className="max-w-full overflow-hidden"> */}
      <ReactImageGallery
        items={images}
        showFullscreenButton={false}
        showPlayButton={false}
      />
      {/* </div> */}
    </Modal>
  );
};
