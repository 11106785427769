import { useDisclosure } from "@mantine/hooks";
import { wsLink } from "../utils/format";
import Drawer from "./Drawer";
import ImageWithFadeIn from "./FadeInImage";
import GenericAnchor from "./GenericAnchor";

interface NavDrawerProps {
  menuDisclosure: ReturnType<typeof useDisclosure>;
}

const NavDrawer = ({ menuDisclosure }: NavDrawerProps) => {
  return (
    <Drawer.Main opened={menuDisclosure[0]} handlers={menuDisclosure[1]}>
      <Drawer.Body className="h-full flex justify-between">
        <div className="flex flex-col items-center justify-center gap-4 py-[22px]">
          <ImageWithFadeIn
            src="/assets/landing/Logo.svg"
            alt="Logo"
            className="h-10 cursor-pointer"
          />
          <span className="text-text font-light text-sm leading-none">
            FROM BEATS TO BUSINESS
          </span>
        </div>
        <div className="flex flex-col flex-1 basis-0 items-center justify-center w-full">
          <DrawerNavItem href="/">HOME</DrawerNavItem>
          <DrawerNavItem href="/agency">AGENCY</DrawerNavItem>
          <DrawerNavItem href="/label">LABEL</DrawerNavItem>
          <DrawerNavItem href="/merch">MERCH</DrawerNavItem>
          <DrawerNavItem href="/customs">CUSTOMS</DrawerNavItem>
          <DrawerNavItem href="/studio">STUDIO</DrawerNavItem>
          {/* <DrawerNavItem href="#">WORKSHOP</DrawerNavItem>
          <DrawerNavItem href="#">PODCAST</DrawerNavItem> */}
          {/* <DrawerNavItem href="/workshop">WORKSHOP</DrawerNavItem>
          <DrawerNavItem href="/podcast">PODCAST</DrawerNavItem> */}
        </div>
        <div className="w-full block mt-auto left-0 px-4 py-6 sm:px-6 sm:py-8 md:px-10 md:py-12 pt-12">
          <div className="w-full flex flex-col items-center justify-center gap-4 xs:gap-6">
            <ImageWithFadeIn
              src="/assets/landing/footer.svg"
              alt="A PU4LYF COMPANY"
              className="w-48 h-5"
            />
            <div className="flex items-center gap-4 xs:gap-5">
              <GenericAnchor href="https://www.youtube.com/@PU4LYF">
                <ImageWithFadeIn
                  src="/assets/landing/Youtube.svg"
                  alt="Youtube"
                  className="h-5 cursor-pointer"
                />
              </GenericAnchor>
              <GenericAnchor href={wsLink("PU4LYF")}>
                <ImageWithFadeIn
                  src="/assets/landing/Whatsapp.svg"
                  alt="Whatsapp"
                  className="h-6 cursor-pointer"
                />
              </GenericAnchor>
              <GenericAnchor href="https://www.instagram.com/pu4lyf.ent/">
                <ImageWithFadeIn
                  src="/assets/landing/Instagram.svg"
                  alt="Instagram"
                  className="h-6 cursor-pointer"
                />
              </GenericAnchor>
              <GenericAnchor href="https://www.linkedin.com/in/rabbitmac">
                <ImageWithFadeIn
                  src="/assets/landing/LinkedIn.svg"
                  alt="LinkedIn"
                  className="h-6 cursor-pointer"
                />
              </GenericAnchor>
              <GenericAnchor href="https://www.twitter.com/PU4LYF_ent">
                <ImageWithFadeIn
                  src="/assets/landing/Twitter.svg"
                  alt="Twitter"
                  className="h-6 cursor-pointer"
                />
              </GenericAnchor>
              <GenericAnchor href="https://www.facebook.com/PU4LYF.Entertainment">
                <ImageWithFadeIn
                  src="/assets/landing/Facebook.svg"
                  alt="Facebook"
                  className="h-6 cursor-pointer"
                />
              </GenericAnchor>
              <GenericAnchor href="https://en.wikipedia.org/wiki/Rabbit_Mac">
                <ImageWithFadeIn
                  src="/assets/landing/Wiki.png"
                  alt="Wiki"
                  className="h-6 cursor-pointer"
                />
              </GenericAnchor>
            </div>
            <div className="text-center">
              <span
                className="text-text font-light text-xs xs:text-sm"
                style={{ fontFamily: "Arial Narrow" }}
              >
                All rights reserved PU4LYF Entertainment Sdn. Bhd.
              </span>
            </div>
          </div>
        </div>
      </Drawer.Body>
    </Drawer.Main>
  );
};

export default NavDrawer;

const DrawerNavItem = ({ href, children }: any) => {
  return (
    <a href={href} className="w-full">
      <div className="flex w-full py-6 items-center justify-center cursor-pointer text-[24px] text-secondary-text hover:bg-primary hover:text-black transform duration-150">
        {children}
      </div>
    </a>
  );
};
